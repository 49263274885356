import {Module, NextModule} from '@backstage-components/base';
import React from 'react';
import {StackLayout, StackLayoutDefinition} from './StackLayout';
import {ComponentDefinition} from './StackLayoutDefinition';

type Kind = StackLayoutDefinition['component'];

type Props = StackLayoutDefinition['props'];

export const StackLayoutModule: Module<Kind, Props> = {
  isContent(value): value is StackLayoutDefinition {
    return false;
  },
  isLayout(value): value is StackLayoutDefinition {
    return (
      value.component === ComponentDefinition.reactName &&
      value.cid === ComponentDefinition.id
    );
  },
  tryRender(definition) {
    if (StackLayoutModule.isContent(definition)) {
      return React.createElement(StackLayout, definition);
    } else {
      return NextModule;
    }
  },
};
