export {StackLayout, StackLayoutBase} from './StackLayout';
export type {StackLayoutDefinition, StackLayoutProps} from './StackLayout';
export {
  ComponentDefinition,
  defaultFieldData,
  schema,
  uiSchema,
} from './StackLayoutDefinition';
export type {SchemaType} from './StackLayoutDefinition';
export {StackLayoutPreview, StackLayoutPreviewBase} from './StackLayoutPreview';

import {Registry} from '@backstage-components/base';
import {ComponentDefinition} from './StackLayoutDefinition';
import {StackLayoutModule} from './StackLayoutModule';
Registry.register(ComponentDefinition, StackLayoutModule);
