import {
  ModuleCategory,
  SchemaTypeHelper,
  SingleUnion,
  StringEnum,
  SubscribesTo,
  backgroundSchema,
  createComponentAdmin,
  layoutSchema,
  opacitySchema,
  scrollToDescription,
  sizingSchema,
  spacingSchema,
} from '@backstage-components/base';
import {Static, Type} from '@sinclair/typebox';

export const reactName = 'Stacked';
export const name = reactName;
export const description = reactName;
const category: ModuleCategory = 'layout';

/**
 * The 6 possible orientations for a position
 */
export const positions = [
  '',
  'unset',
  'static',
  'relative',
  'fixed',
  'absolute',
  'sticky',
] as const;

/**
 * The four directional properties that can be set after designating a position
 */

const directionalPositionSchema = {
  top: Type.String({title: 'Top'}),
  left: Type.String({title: 'Left'}),
  bottom: Type.String({title: 'Bottom'}),
  right: Type.String({title: 'Right'}),
};

const Positioning = Type.Intersect([
  Type.Object({
    position: StringEnum(positions),
  }),
  Type.Partial(Type.Object(directionalPositionSchema)),
]);

export type Positioning = Static<typeof Positioning>;

export type Position = (typeof positions)[number];

const justifyPreset = [
  'start',
  'center',
  'end',
  'space-between',
  'space-around',
  'space-evenly',
  '',
] as const;
export type JustifyPreset = (typeof justifyPreset)[number];

export const schema = Type.Object(
  {
    autoLayout: Type.Optional(
      Type.Boolean({
        title: 'Enable Auto Layout',
        default: false,
      })
    ),
    layout: Type.Optional(
      Type.Intersect(
        [
          layoutSchema,
          Type.Object({
            justifyPreset: Type.Optional(
              StringEnum(justifyPreset, {
                title: 'Justify',
              })
            ),
          }),
        ],
        {
          description:
            'If a "justify preset" is selected, will return a flex-layout based on selection. Falls back on orientation',
        }
      )
    ),
    spacing: Type.Optional(spacingSchema),
    positioning: Type.Optional(
      Type.Object(
        {
          position: Type.Optional(
            StringEnum(positions, {
              title: 'Position',
              default: '',
            })
          ),
        },
        {
          dependencies: {
            position: {
              oneOf: positions.map((p) => {
                /**
                 * The directional schema only has effect if the position is not static. That is, when the position is static, the directional properties are ignored. Thus, we conditionally not render the directionalPositionSchema if the position is static.
                 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/top
                 */
                const effectiveDirectionalSchema =
                  p !== 'static' ? directionalPositionSchema : {};
                return {
                  properties: {
                    position: {
                      enum: [p],
                    },
                    ...effectiveDirectionalSchema,
                  },
                };
              }),
            },
          },
        }
      )
    ),
    background: Type.Optional(backgroundSchema),
    sizing: Type.Optional(
      Type.Pick(sizingSchema, [
        'width',
        'height',
        'minWidth',
        'maxWidth',
        'minHeight',
        'maxHeight',
      ])
    ),
    opacity: Type.Optional(opacitySchema),
  },
  {
    description:
      'If autoLayout is checked, a flex property shorthand will be passed to children. False by default',
  }
);

export const defaultFieldData: Static<typeof schema> = {
  layout: {orientation: 'vertical'},
  sizing: {},
  spacing: {},
  autoLayout: false,
  positioning: {position: ''},
};

export const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    initialSection: 'Styling',
    sections: [
      ['Properties', []],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [
              ['Layout', ['autoLayout', 'layout']],
              ['Spacing', ['spacing']],
              ['Sizing', ['sizing']],
              ['Positioning', ['positioning']],
              ['Background', ['background']],
              ['Opacity', ['opacity']],
              ['Custom Styles', ['styleAttr']],
            ],
          },
        ],
      ],
      [
        'Animations',
        [
          {
            'ui:template': 'accordion',
            sections: [['Animations', ['animationStates']]],
          },
        ],
      ],
    ],
  },
  layout: {
    orientation: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
      },
    },
    justifyPreset: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
        deselectable: true,
      },
    },
    align: {
      'ui:widget': 'LayoutWidget',
      'ui:options': {
        inline: true,
        deselectable: true,
      },
    },
    gap: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'gap',
      },
    },
    'ui:order': ['orientation', 'align', 'justifyPreset', 'gap', '*'],
  },
  spacing: {
    margin: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'margin',
      },
    },
    padding: {
      'ui:widget': 'SpacingWidget',
      'ui:options': {
        type: 'padding',
      },
    },
  },
  sizing: {
    'ui:options': {
      columns: 2,
    },
    width: {
      'ui:widget': 'SizingWidget',
    },
    height: {
      'ui:widget': 'SizingWidget',
    },
    minWidth: {
      'ui:widget': 'SizingWidget',
    },
    maxWidth: {
      'ui:widget': 'SizingWidget',
    },
    minHeight: {
      'ui:widget': 'SizingWidget',
    },
    maxHeight: {
      'ui:widget': 'SizingWidget',
    },
  },
  positioning: {
    'ui:order': ['position', 'top', 'left', 'bottom', 'right', '*'],
  },
  background: {
    backgroundColor: {
      'ui:widget': 'color',
    },
    backgroundImage: {
      'ui:widget': 'AssetSelectWidget',
      'ui:options': {
        buttonTitle: 'Select Asset',
      },
    },
  },
  opacity: {
    opacity: {
      'ui:widget': 'range',
      'ui:options': {
        type: 'percentage',
      },
    },
  },
};

const instructions = SingleUnion(
  SubscribesTo({
    topic: `${reactName}:scrollTo`,
    description: scrollToDescription,
    meta: {
      scrollX: Type.Optional(Type.String()),
      scrollY: Type.Optional(Type.String()),
      anchorElId: Type.Optional(Type.String()),
      elementId: Type.Optional(Type.String()),
    },
  })
);

export const ComponentDefinition = createComponentAdmin({
  id: '57accedd-28cd-4c27-9d27-2b185af3a88c',
  reactName,
  name: 'Stacked Layout',
  slug: reactName,
  description: 'Stacked layout',
  version: 1,
  defaultFieldData,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  schema,
  uiSchema,
  instructions,
  category,
})
  .withAnimationStates()
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition>;
