/// <reference types="@emotion/react/types/css-prop" />
import {ModuleNode, WithHtmlId} from '@backstage-components/base';
import styled from '@emotion/styled';
import {FC, PropsWithChildren} from 'react';
import {computeLayout} from './StackLayout';
import {ComponentDefinition, SchemaType} from './StackLayoutDefinition';

export type StackedProps = SchemaType;

type StyledContainerProps = Pick<
  StackedProps,
  'sizing' | 'background' | 'styleAttr'
>;

const Styled = styled.div<StyledContainerProps>`
  ${({sizing, background, styleAttr}) =>
    `
    ${
      background?.backgroundImage &&
      `background-image: url(${background?.backgroundImage})`
    };
    ${
      background?.backgroundColor &&
      `background-color: ${background?.backgroundColor}`
    };
    ${`background-repeat: ${
      background?.backgroundRepeat ??
      ComponentDefinition.defaultFieldData.background?.backgroundRepeat
    }`};
    ${`background-position: ${
      background?.backgroundPosition ??
      ComponentDefinition.defaultFieldData.background?.backgroundPosition
    }`};
    ${`background-size: ${
      background?.backgroundSize ??
      ComponentDefinition.defaultFieldData.background?.backgroundSize
    }`};
    ${sizing?.minHeight && `min-height: ${sizing?.minHeight}`};
    ${sizing?.maxHeight && `max-height: ${sizing?.maxHeight}`};
    ${sizing?.height && `height: ${sizing?.height}`};
    ${styleAttr}`};
`;

export const StackLayoutPreviewBase: FC<
  PropsWithChildren<ModuleNode<'Stacked', StackedProps> & WithHtmlId>
> = ({children, module: {props, id}}) => {
  const {justifyPreset, align, orientation} = props.layout || {
    orientation: 'vertical',
  };
  const layoutStyle = computeLayout(justifyPreset, align, orientation);
  return (
    <Styled
      id={id}
      css={{...layoutStyle}}
      background={props.background}
      sizing={props.sizing}
      styleAttr={props.styleAttr}
    >
      {children}
    </Styled>
  );
};

export const StackLayoutPreview: FC<
  ModuleNode<'Stacked', StackedProps> & WithHtmlId
> = (props) => {
  const moduleProps = props.module.props;
  const Slot = props.slotComponent;
  return (
    <StackLayoutPreviewBase {...props}>
      <Slot
        slotName="items"
        orientation={moduleProps.layout?.orientation}
        autoLayout={moduleProps.autoLayout}
      />
    </StackLayoutPreviewBase>
  );
};
